import React from 'react';
import Header from './Header';
import Footer from './Footer';
import moment from "moment";
import 'moment/locale/id' 
moment.locale('id')


const Page404 = () => {


  return (
<>
  <Header/>
  <main id="main">
      <div className="col-md-12 bg-image image"   >
        <img src="/assets/img/404.png" className="img-fluid imageContent" alt="" />
      </div>
  </main>
  <Footer/>
</>
  );
}
export default Page404;