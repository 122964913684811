import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilNotes,
  cilHome
} from '@coreui/icons'
import { CNavItem } from '@coreui/react'

const _navCMO = [
  {
    component: CNavItem,
    name: 'Home',
    to: '/admin/home',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
    
  },
  {
    component: CNavItem,
    name: 'Berita',
    to: '/admin/berita',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    
  },
  
  
]

export default _navCMO
