import React from 'react'

const Home = React.lazy(() => import('./views/home/Home'))
const Berita = React.lazy(() => import('./views/berita/Berita'))
const TambahBerita = React.lazy(() => import('./views/berita/TambahBerita'))
const EditBerita = React.lazy(() => import('./views/berita/EditBerita'))
const Karir = React.lazy(() => import('./views/karir/Karir'))
const TambahKarir = React.lazy(() => import('./views/karir/TambahKarir'))
const EditKarir = React.lazy(() => import('./views/karir/EditKarir'))
const Team = React.lazy(() => import('./views/team/Team'))
const User = React.lazy(() => import('./views/user/User'))
const Financial = React.lazy(() => import('./views/financial/FInancial'))



const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/home', name: 'Home', element: Home },
  { path: '/berita', name: 'Berita', element: Berita },
  { path: '/tambah_berita', name: 'Tambah Berita', element: TambahBerita },
  { path: '/edit_berita/:id', name: 'Edit Berita', element: EditBerita },
  { path: '/karir', name: 'Karir', element: Karir },
  { path: '/tambah_karir', name: 'Tambah Karir', element: TambahKarir },
  { path: '/edit_karir/:id', name: 'Edit Karir', element: EditKarir },
  { path: '/team', name: 'Karir', element: Team },
  { path: '/user', name: 'User', element: User },
  { path: '/financial', name: 'Financial', element: Financial },
  
]

export default routes
