import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Main";
import Berita from "./Berita";
import Tentang from "./Tentang";
import Karir from "./Karir";
import Kaluna from "./Kaluna";
import Kiosery from "./Kiosery";
import Nusumma from "./Nusumma";
import DetailBerita from "./DetailBerita";
import DetailKarir from "./DetailKarir";
import Admin from "./admin";
import Login from "./admin/views/pages/login/Login";
import Page404 from "./Page404";
import ComingSoon from "./ComingSoon";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Page404 />} />
        <Route path="/" element={<Main />} />
        <Route path="/berita" element={<Berita />} />
        <Route path="/detailberita/:id/:link" element={<DetailBerita />} />
        <Route path="/karir" element={<Karir />} />
        <Route path="/kaluna" element={<Kaluna />} />
        <Route path="/kiosery" element={<Kiosery />} />
        <Route path="/nusumma" element={<Nusumma />} />
        <Route path="/detailkarir/:id/:link" element={<DetailKarir />} />
        <Route path="/tentang" element={<Tentang />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/financial_services" element={<Nusumma/>} />
        <Route path="/digital_services" element={<ComingSoon />} />
        <Route path="/professional_services" element={<ComingSoon />} />
        <Route path="/sme_incubation" element={<Kaluna />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
