import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Carousels2 from "./Carousels2";
import { ExternalLink } from "react-external-link";
import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

const Nusumma = () => {
  const [assets, setAssets] = useState("");
  const [office, setOffice] = useState("");
  const [customer, setCustomer] = useState("");
  const [product, setProduct] = useState("");
  const [npl, setNpl] = useState("");
  const [roa, setRoa] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const getFinancial = async () => {
    const response = await axios.get(`https://api.nbagroup.co.id/financial/`);
    setAssets(response.data.assets);
    setOffice(response.data.office);
    setCustomer(response.data.customer);
    setProduct(response.data.product);
    setNpl(response.data.npl);
    setRoa(response.data.roa);
    }
    getFinancial();
  }, []);

useEffect(() => {
  const script = document.createElement('script');
  script.src = "../../../../assets/js/portfolio.js";
  script.async = true;
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  }
}, []);

  return (
    <>
      <Header />

      <main id="main">
      <div className="col-md-12 bg-image image"   >
      <img src="assets/img/Nusumma.png" className="img-fluid imageContent" alt="" />
      </div>

        <section id="clients" className="clients section-bg">
          <div className="container" >
            <div className="row justify-content-center no-gutters clients-wrap clearfix"  style={{ marginTop: "30px" }}>
              <div className="col-md-10 bg-image " style={{marginTop:"-30px"}}>
                <h3 style={{ textAlign: "left" }}>{t("h.nusumma")}</h3>
              </div>
              <div className="col-md-10 bg-image " data-aos="fade-up">
                <p style={{ textAlign: "justify", fontSize: "16px" }}>
                {t("desc.nusumma")}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg" style={{ marginTop: "-90px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }} >
              <h2>{t("hvisi.nusumma")}</h2>
            </div>
            <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up" style={{ marginTop: "1px" }}>
              <div className="col-md-10 bg-image ">
                <h4 style={{ textAlign: "center"}}>{t("visi.nusumma")}</h4>
              </div>
            </div>
          </div>
        </section>




        <section id="services" className="services section-bg" style={{ marginTop: "-70px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>{t("hmisi.nusumma")}</h2>
            </div>
            
            <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up" style={{ marginTop: "30px" }}>
              <div className="col-md-9 bg-image ">
                <ol>
                <h5 style={{ textAlign: "justify", marginTop: "-25px" }}>
                 <li>{t("misi1.nusumma")}</li>
                </h5>
                <br />
                <h5 style={{ textAlign: "justify", marginTop: "-25px",  }}>
                <li>{t("misi2.nusumma")}</li>
                </h5>
                <br />
                <h5 style={{ textAlign: "justify", marginTop: "-25px"}}>
                  <li>{t("misi3.nusumma")}</li>
                </h5>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg" style={{ marginTop: "-60px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>{t("hbudaya.nusumma")}</h2>
            </div>
            <div className="row justify-content-center">
            <div className="col-md-8 bg-image ">
            <Carousels2 />
            </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg" style={{ marginTop: "-60px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>{t("hpeta.nusumma")}</h2>
            </div>

            <div className="row justify-content-center">
            <div className="col-md-8 bg-image ">
              <img src="assets/img/Peta.png" className="img-fluid imageNusumma" alt="" />
            </div>
            </div> 
          </div>
        </section>

                  <section id="services" className="services section-bg" style={{ marginTop: "-60px" }}>
      <div className="container" data-aos="fade-up">

        <div className="section-title" style={{ marginBottom: "-30px" }}>
          <h2>Overview Nusumma</h2>
        </div>

        <div className="row justify-content-center">
         <div className="col col-md-8">
         <div className="row ">
          <div className="col col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
            <div className="row align-items-center">
                  <div className="col-md-4 marginNusumma" >
                    <img src="/assets/img/services/assets.png" className="gambarNusumma1" alt=""   />
                  </div>
                  <div className="col-md-8" >
                  <h5> Rp. {assets} Milliar + Total Assets</h5>
                  </div>
                  </div>
            </div>
          </div>

          <div className="col col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="row align-items-center">
                  <div className="col-md-4" style={{marginBottom:"30px"}}>
                    <img src="/assets/img/services/kantor.png" className="img-fluid gambarNusumma" alt="" />
                  </div>
                  <div className="col-md-8" >
                  <h5> {office} {t("shkantor.nusumma")}</h5>
                  </div>
                  </div>
            </div>
          </div>

          <div className="col col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="row align-items-center">
                  <div className="col-md-4" style={{marginBottom:"30px"}}>
                    <img src="/assets/img/services/nasabah.png" className="img img-fluid gambarNusumma" alt="" />
                  </div>
                  <div className="col-md-8" >
                  <h5> {customer} Ribu + {t("nasabah.nusumma")}</h5>
                  </div>
                  </div>
            </div>
          </div>

          <div className="col col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="row align-items-center">
                  <div className="col-md-4" style={{marginBottom:"30px"}}>
                    <img src="/assets/img/services/produk.png" className="img img-fluid gambarNusumma" alt="" />
                  </div>
                  <div className="col-md-8" >
                  <h5> {product} + {t("produk.nusumma")}</h5>
                  </div>
                  </div>
            </div>
          </div>

          <div className="col col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="row align-items-center">
                  <div className="col-md-4" style={{marginBottom:"30px"}}>
                    <img src="/assets/img/services/npl.png" className="gambarNusumma1"  alt="" />
                  </div>
                  <div className="col-md-8" >
                  <h5> NPL {npl}%</h5>
                  </div>
                  </div>
            </div>
          </div>

          <div className="col col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="row align-items-center">
                  <div className="col-md-4" style={{marginBottom:"30px"}}>
                  <div className="client-logo">
                    <img src="/assets/img/services/roa.png" className="img img-fluid gambarNusumma" alt="" />
                  </div>
                  </div>
                  <div className="col-md-8" >
                  <h5> ROA {roa}%</h5>
                  </div>
                  </div>
            </div>
          </div>
          </div>
          </div>

        </div>

      </div>
    </section>

        <section id="portfolio" className="portfolio section-bg" style={{ marginTop: "-60px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>{t("hpengurus.nusumma")}</h2>
            </div>

            <div className="row justify-content-center portfolio-container">
            <div className="col-md-8">
            <div className="testimonial-slider swiper" data-aos="fade-up" data-aos-delay="100">
            <Swiper
              speed={400}
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                spaceBetween={50}
                slidesPerView={4}
                onSlideChange={() => console.log('slide change')}
                
                onSwiper={(swiper) => console.log(swiper)}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                <div className="portfolio-item filter-web">
                  <div className="portfolio-wrap" style={{border: "5px solid #fff", borderRadius: "5%"}}>
                    <img src="assets/img/Direksi1.png" className="img-fluid" alt=""  />
                    <div className="portfolio-info">
                      <h4>Giri Batjo</h4>
                    </div>
                  </div>
                </div>
                
                </SwiperSlide>
                 <SwiperSlide>
                <div className="portfolio-item filter-web">
                  <div className="portfolio-wrap" style={{border: "5px solid #fff", borderRadius: "5%"}}>
                    <img src="assets/img/Direksi3.png" className="img-fluid" alt=""  />
                    <div className="portfolio-info">
                      <h4>Alex Pangemanan</h4>
                    </div>
                  </div>
                </div>
                </SwiperSlide>
                 <SwiperSlide>
                <div className="portfolio-item filter-web">
                  <div className="portfolio-wrap" style={{border: "5px solid #fff", borderRadius: "5%"}}>
                    <img src="assets/img/Direksi7.png" className="img-fluid" alt=""  />
                    <div className="portfolio-info">
                      <h4>Lingga Putra</h4>
                    </div>
                  </div>
                </div>
                </SwiperSlide>
                 <SwiperSlide>
                <div className="portfolio-item filter-web">
                  <div className="portfolio-wrap" style={{border: "5px solid #fff", borderRadius: "5%"}}>
                    <img src="assets/img/Direksi2.png" className="img-fluid" alt=""  />
                    <div className="portfolio-info">
                      <h4>Ismail Zulkarnain</h4>
                    </div>
                  </div>
                </div>
                </SwiperSlide>
                 <SwiperSlide>
                <div className="portfolio-item filter-web">
                  <div className="portfolio-wrap" style={{border: "5px solid #fff", borderRadius: "5%"}}>
                    <img src="assets/img/Direksi6.png" className="img-fluid" alt=""  />
                    <div className="portfolio-info">
                      <h4>Eko Setiawan</h4>
                     </div>
                  </div>
                </div>
                </SwiperSlide>
                 <SwiperSlide>
                <div className="portfolio-item filter-web">
                  <div className="portfolio-wrap" style={{border: "5px solid #fff", borderRadius: "5%"}}>
                    <img src="assets/img/Direksi8.png" className="img-fluid" alt=""  />
                    <div className="portfolio-info">
                      <h4>Suhaedi</h4>
                    </div>
                  </div>
                </div>
                </SwiperSlide>
              </Swiper>
 
            </div>
          </div>
          </div>
          </div>
        </section>


        <section id="clients" className="clients section-bg" style={{ marginTop: "-60px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>{t("hubungi")}</h2>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8">
                <p className="text-center">{t("informasi")} <ExternalLink href="https://nusumma.co.id" target="_blank"><b>www.nusumma.co.id</b></ExternalLink></p>
                </div>
            </div>
           
          </div>
        </section>
        

      </main>
      <Footer />
    </>
  );
};
export default Nusumma;
