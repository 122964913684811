import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";

function Carousels() {
  const { t } = useTranslation();
  return (
    <Carousel variant="dark">
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src={t("nusumma.pelita1")} alt="First slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100 " src={t("nusumma.pelita2")} alt="Second slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src={t("nusumma.pelita3")} alt="Third slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src={t("nusumma.pelita4")} alt="Third slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src={t("nusumma.pelita5")} alt="Third slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src={t("nusumma.pelita6")} alt="Third slide" />
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carousels;
