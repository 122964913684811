import Home from "./Home";

function Main() {
  return (
    <>
      <main>
       <Home/>
      </main>
    </>
  );
}
export default Main;
