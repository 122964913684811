
import { Suspense, useState } from "react";
import i18n from './translations/i18n';
import RouterPage from "./components/RouterPage"
import Loading from "./translations/Loading";
import LocaleContext from './translations/LocaleContext.js'


function App() {
  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', (lng) => setLocale(i18n.language));
  return (
    <>
    <LocaleContext.Provider value={{locale, setLocale}}>
    <Suspense fallback={<Loading />}>
    <RouterPage/>
    </Suspense>
    </LocaleContext.Provider>
    </>
  );
}

export default App;


