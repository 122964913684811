import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilNotes,
  cilContact,
  cilUser,
  cilBriefcase,
  cilHome,
  cilCreditCard
} from '@coreui/icons'
import { CNavItem } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Home',
    to: '/admin/home',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
    
  },
  {
    component: CNavItem,
    name: 'Berita',
    to: '/admin/berita',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    
  },
  {
    component: CNavItem,
    name: 'Karir',
    to: '/admin/karir',
    icon: <CIcon icon={cilBriefcase} customClassName="nav-icon" />,
    
  },
  {
    component: CNavItem,
    name: 'Team',
    to: '/admin/team',
    icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
    
  },
  {
    component: CNavItem,
    name: 'Users',
    to: '/admin/user',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    
  },
  {
    component: CNavItem,
    name: 'Financial',
    to: '/admin/financial',
    icon: <CIcon icon={cilCreditCard} customClassName="nav-icon" />,
  },
  
]

export default _nav
